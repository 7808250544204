import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const BurnRateGraph = ({ width, height, data, isForecast, exclude = [] }) => {
  // console.log('Data:', data)
  const svgRef = useRef();

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const svg = d3.select(svgRef.current);
    const { width, height } = svg.node().getBoundingClientRect();
    const margin = { top: 20, right: 30, bottom: 30, left: 60 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Scales
    const xScale = d3.scaleTime()
      .domain(d3.extent(data, d => d.date))
      .range([0, innerWidth]);

    // Filter the data based on the exclude array
    const filteredData = data
      .filter(d => isForecast || d.Type !== "Forecast") // Filter forecast data if isForecast is false
      .map(d => {
        const filteredEntry = { ...d };
        exclude.forEach(excludedKey => {
          delete filteredEntry[excludedKey];
        });
        return filteredEntry;
      });

    // Calculate yMax based on filtered data
    const yMax = d3.max(filteredData, d =>
      Math.max(d.Income || 0, d.Expenses || 0, d.Burnrate || 0, d.Cashflow || 0)
    );

    const yDomainMax = yMax * 1.10;  // Extend yMax by 5% for padding
    const yScale = d3.scaleLinear()
      .domain([0, yDomainMax])
      .range([innerHeight, 0]);

    // Create 5 custom ticks (including 0 and yDomainMax)
    const yTicks = d3.range(0, 6).map(i => (i * yDomainMax) / 4);

    // Line generator
    const line = d3.line()
      .curve(d3.curveMonotoneX)
      .x(d => xScale(d.date))
      .y(d => yScale(d.value));

    svg.selectAll("*").remove();
    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Y-Axis
    const yAxis = d3.axisLeft(yScale)
      .tickValues(yTicks) // Use custom tick values
      .tickSize(0)
      .tickPadding(10)
      .tickFormat(d3.format(".0s"));

    g.append('g')
      .call(yAxis)
      .selectAll('text')
      .attr('fill', '#213038')
      .attr('font-size', '17px');

    g.selectAll('path.domain').attr('stroke', 'none');

    // X-Axis
    const firstDate = new Date(data[0].date);
    const lastDate = new Date(data[data.length - 1].date);
    const numTicks = 8;
    const customTicks = d3.range(numTicks).map(i => {
      const date = new Date(firstDate.getTime() + i * (lastDate - firstDate) / (numTicks - 1));
      return new Date(date.setDate(1));
    });

    const xAxis = d3.axisBottom(xScale)
      .tickSize(0)
      .tickPadding(10)
      .tickFormat(d3.timeFormat("%b %Y"))
      .tickValues(customTicks);

    g.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(xAxis)
      .selectAll('text')
      .attr('fill', '#213038')
      .attr('font-weight', '300')
      .style('font-size', '15px');

    // Tooltip setup
    const tooltip = d3.select('body').append('div')
      .style('position', 'absolute')
      .style('background', '#ffffff')
      .style('box-shadow', '0 0 10px rgba(0, 0, 0, 1)')
      .style('padding', '15px')
      .style('display', 'none')
      .style('pointer-events', 'none')
      .style('justify-content', 'center')
      .style('color', '#213038')
      .style('font-weight', '300')
      .style('font-size', '15px')
      .style('border-radius', '35px')
      .style('min-width', '120px')
      .style('height', '100px')
      .style('z-index', '5')
      .style('flex-direction', 'column');

    const formatValue = (value) => (value !== undefined ? `R ${value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A');
    const formatDate = (date) => {
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) {
        return '';
      }

      const month = months[parsedDate.getMonth()];
      const year = parsedDate.getFullYear();
      return `${month} ${year}`;
    };

    const showTooltip = (event, nearestData) => {
      if (!nearestData) {
        return;
      }
      tooltip
        .style('left', (event.pageX + 10) + 'px')
        .style('top', (event.pageY + 10) + 'px')
        .style('display', 'flex')
        .html(`
          <p style="line-height: 0px; font-size: 20px; align-self: center">${formatDate(nearestData.date)}</p>
          <p style="line-height: 0px; font-size: 20px; align-self: center">${nearestData.type}</p>
          <div style='display: flex; flex-direction: row; align-items: center; justify-content: space-between'>
              <p style="line-height: 0px; font-size: 18px; font-weight: 300; display: flex; align-self:center;margin: 0px;">Amount:</p>
              <p style="line-height: 0px; font-size: 18px; font-weight: 700; display: flex; align-self:center">${formatValue(nearestData.value)}</p>
          </div>
        `);
    };

    const hideTooltip = () => {
      tooltip.style('display', 'none');
    };

    // Define lines for different metrics
    const lines = [
      { name: 'Income', color: '#EFB86F', data: data.map(d => ({ date: d.date, value: d.Income, type: d.Type })) },
      { name: 'Expenses', color: '#A62626', data: data.map(d => ({ date: d.date, value: d.Expenses, type: d.Type })) },
      { name: 'Burnrate', color: '#7A8F9D', data: data.map(d => ({ date: d.date, value: d.Burnrate, type: d.Type })) },
      { name: 'Cashflow', color: '#213038', data: data.map(d => ({ date: d.date, value: d.Cashflow, type: d.Type })) },
    ];

    lines.forEach(lineData => {
      // Check if the line should be excluded
      if (exclude.includes(lineData.name)) {
        return;
      }

      const actualData = lineData.data.filter(d => d.type !== "Forecast");
      const forecastData = lineData.data.filter(d => d.type === "Forecast");

      const drawLineAndCircles = (data, isForecast) => {
        const classNameSuffix = isForecast ? '-forecast' : '-actual';

        // Draw the line
        g.append('path')
          .datum(data)
          .attr('class', `${lineData.name}${classNameSuffix}`)
          .attr('fill', 'none')
          .attr('stroke', lineData.color)
          .attr('stroke-width', isForecast ? 2 : 4)
          .attr('stroke-dasharray', isForecast ? '10' : 'none')
          .on('mouseover', function (event, d) {
            const [x, y] = d3.pointer(event, this);
            const xDate = xScale.invert(x);
            const nearestData = data.reduce((prev, curr) => Math.abs(curr.date - xDate) < Math.abs(prev.date - xDate) ? curr : prev);
            showTooltip(event, nearestData);
          })
          .on('mouseout', hideTooltip)
          .attr('d', line);

        // Draw the circles
        g.selectAll(`.${lineData.name}-circle${classNameSuffix}`)
          .data(data)
          .enter().append('circle')
          .attr('class', `${lineData.name}-circle${classNameSuffix}`)
          .attr('cx', d => xScale(d.date))
          .attr('cy', d => yScale(d.value))
          .attr('r', isForecast ? 3 : 4)
          .attr('fill', lineData.color)
          .on('mouseover', function (event, d) {
            showTooltip(event, d);
          })
          .on('mouseout', hideTooltip);
      };

      drawLineAndCircles(actualData, false);
      if (isForecast) {
        drawLineAndCircles(forecastData, true);
      }
    });
  }, [data, isForecast, width, height, exclude]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
      <svg ref={svgRef} width={width} height={height}></svg>
    </div>
  );
};

export default BurnRateGraph;
