import React from 'react';
import './style.scss';
import Icon from '../../assets/images/AionsDashIcon.svg';
import miniIcon from '../../assets/images/AionsDashMini.svg';

function HandlerSidebar({ Active, onMouseEnter, onMouseLeave, pageClicked, pageSelected, logout, role }) {
    return (
        <div className={`InvesteeSidebar ${Active ? 'InvesteeSidebarActive' : ''}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ transition: 'width 1s ease' }}>
            <img src={Active ? Icon : miniIcon} alt='' />
            <div className='pagesSection'>
                <div className={`availablePages ${(pageSelected === 'AlkemiaBriefing' ? 'availablePagesActive' : '')}`} id='AlkemiaBriefing' onClick={pageClicked}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Alkemia' : 'A'}</p>
                </div>
                {(role === 'Admin') ?
                    <div className={`availablePages ${(pageSelected === 'Overview' ? 'availablePagesActive' : '')}`} id='Overview' onClick={pageClicked}>
                        <div className='availablePageSelector'></div>
                        <p className='selectedPageFont'>{Active ? 'Overview' : 'O'}</p>
                    </div>
                    : null}
                <div className={`availablePages ${(pageSelected === 'Reports' || pageSelected === 'CompanyReport' || pageSelected === 'Metrics' ? 'availablePagesActive' : '')}`} id='Reports' onClick={pageClicked}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Reports' : 'R'}</p>
                </div>
                {(role === 'Admin') ?
                    <>
                        <div className={`availablePages ${(pageSelected === 'ACT' ? 'availablePagesActive' : '')}`} id='ACT' onClick={pageClicked}>
                            <div className='availablePageSelector'></div>
                            <p className='selectedPageFont'>{Active ? 'ACT' : 'A'}</p>
                        </div>
                    </> : null}
                <div className={`availablePages ${(pageSelected === 'Logout' ? 'availablePagesActive' : '')}`} id='Logout' onClick={logout}>
                    <div className='availablePageSelector'></div>
                    <p className='selectedPageFont'>{Active ? 'Logout' : 'L'}</p>
                </div>
            </div>
        </div>
    );
}

export default HandlerSidebar;
