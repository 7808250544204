import React, { useEffect, useState, updateState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import AlkemiaBriefing from './AlkemiaBriefing/AlkemiaBriefing';
import Clients from './Clients/Clients';
import Reports from './Reports/Reports';
import Metrics from './Metrics/Metrics'
import ACT from './ACTReports/ACT';
import Overview from './Overview/Overview';
import Client from './Client';
import CompanyReport from './CompanyReport/CompanyReport'
import './style.scss';
import { Input, View, Button, Card, HandlerSidebar } from '../../components';
import Users from './Users/Users';

var mobile = require('is-mobile');

const HandlerDashboard = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [PageSelected, setPageSelected] = useState('Reports');
    const [IsHovered, setIsHovered] = useState(false);
    const [UserRole, setUserRole] = useState(store.user.Role);
    const [CompanyId, setCompanyId] = useState('');
    useEffect(() => {
    }, []);

    const handleHoverStart = () => {
        setIsHovered(true);
    };
    const handleHoverEnd = () => {
        setIsHovered(false);
    };
    function selectPage(e) {
        setPageSelected(e.currentTarget.id);
        forceUpdate();
    };
    const getCompId = (comp) => {
        setCompanyId(comp.CompId);
        setPageSelected(comp.Page);
        forceUpdate();
    }
    const backClicked = (back) => {
        setPageSelected(back);
        forceUpdate();
    };
    function logout() {
        store.logout()
    }
    if (mobile()) {
        return (
            <View >
            </View>
        );
    } else {
        return (
            <div className='handlerHomePage' style={{ width: `${store.getScreenWidth()}px`, height: `${store.getScreenHeight()}px` }}>
                <div className='handlerSidebar' >
                </div>
                <HandlerSidebar onMouseEnter={handleHoverStart} onMouseLeave={handleHoverEnd} Active={IsHovered} pageClicked={selectPage} pageSelected={PageSelected} logout={logout} role={UserRole} />
                <div className='handlerBody'>
                    {(PageSelected === 'AlkemiaBriefing') ? <AlkemiaBriefing /> : null}
                    {(PageSelected === 'ACT') ? <ACT /> : null}
                    {(PageSelected === 'Overview') ? <Overview /> : null}
                    {(PageSelected === 'Metrics') ? <Metrics compId={CompanyId} back={backClicked}/> : null}
                    {(PageSelected === 'Clients') ? <Clients /> : null}
                    {(PageSelected === 'Reports') ? <Reports pageSelected={getCompId}/> : null}
                    {(PageSelected === 'CompanyReport') ? <CompanyReport compId={CompanyId} back={backClicked} /> : null}
                </div>
            </div>
        );
    }
});

export default HandlerDashboard;
