import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { Input, View, Button, Card, Textbox } from '../../components';
import DashLog from '../../assets/images/DashLog.svg';
import Users from '../AdminDashboard/Users/Users';

var mobile = require('is-mobile');

const SignIn = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [Error, setError] = useState('');
    const [ForgotPassword, setForgotPassword] = useState(false);

    useEffect(() => {
    }, []);

    function emailChanged(e) {
        setEmail(e.target.value);
        forceUpdate();
    }
    function passwordChanged(e) {
        setPassword(e.target.value);
        forceUpdate();
    }
    function loginClicked() {
        if (Email === '') {
            setError('Please enter your email.');
            return;
        }
        if (Password === '') {
            setError('Please enter your password.');
            return;
        }
        let data = {
            Password: Password
        };
        store.setLoading(true);
        store.login(Email, data).then((ret) => {
            if (ret === false) {
                setError('Authentication Failed');
                store.setLoading(false);
                return;
            };
            if (ret === true) {
                const foundUser = store.user;
                if (foundUser.Role === "Company") {
                    navigate('/investee');
                } else if (foundUser.Role === "Design" || foundUser.Role === "Dev") {
                    navigate('/act')
                } else if (foundUser.Role === "Admin") {
                    navigate('/handlerDashboard');
                } else if (foundUser.Role === 'Handler') {
                    navigate('/handlerDashboard')
                } else {
                    navigate('/signin');
                }
                forceUpdate();
            }
        });
    };
    const loginInClicked = async () => {
        if (Email === '') {
            setError('Please enter your email.');
            return;
        };
        if (Password === '') {
            setError('Please enter your password.');
            return;
        };
        let data = {
            Password: Password
        };
        try {
            store.setLoading(true);
            let isLoggedIn = await store.login(Email, data);
            if (!isLoggedIn || isLoggedIn === false) {
                setError('Authentication Failed');
                store.setLoading(false);
            }
            console.log('Logged In ...')
        } catch (e) {
            setError('Authentication Failed');
            store.setLoading(false);
        }
    }
    if (mobile()) {
        return (
            <View className='signin'>
                {/* <img className='signInDetailsMobile' src={dash} /> */}
                <div className='loginInputsMobile'>
                    <Input className="signInInputsMobile" placeholder='Email' value={Email} type='text' onChange={emailChanged} />
                    <Input className="signInInputsMobile" placeholder='Password' type='password' value={Password} style={{ marginBottom: '5px' }} onChange={passwordChanged} onKeyDown={(e) => { if (e && e.key && e.key === 'Enter') { e.preventDefault(); loginClicked(); } }} />
                    {(Error === '') ? null : <p className='formError'>{Error}</p>}
                    <Button className='signInButtonStyle' onClick={loginClicked}>Login</Button>
                </div>
            </View>
        );
    } else {
        return (
            <div className="mainLogin">
                <section id="topLeft"></section>
                <section id="topRight"></section>
                <section id="bottomRight"></section>
                <section id="bottomLeft"></section>
                <img src={DashLog} alt="dashPic" className="DashImage" />
                <Card className='LoginCredentialsCard'>
                    <div className='welcomeMessageContainer'>
                        <p className='welcoomMessage'>Welcome to the
                            <span className='newLine'> Aions dashboard</span>
                        </p>
                    </div>
                    <div className='inputsContainer' style={{ marginTop: '5%' }}>
                        <Textbox placeholder='Email Address' className='emailPasswordInputs' style={{ width: '95%' }} value={Email} type='text' onChange={emailChanged} />
                    </div>
                    <div className='inputsContainer'>
                        <Textbox placeholder='Password' type='password' className='emailPasswordInputs' style={{ width: '95%' }} value={Password} onChange={passwordChanged} onKeyDown={(e) => { if (e && e.key && e.key === 'Enter') { e.preventDefault(); loginClicked(); } }} />
                    </div>
                    {(Error === '') ? ' ' : <p className='formError'>{Error}</p>}
                    {/* <div style={{ width: '100%', height: '5%' }}>
                        {(Error === '') ? ' ' : <p className='formError'>{Error}</p>}
                        <p style={{ alignSelf: 'right' }}>Forgot Password</p>
                    </div> */}
                    <button className='SignInBtn' onClick={loginClicked}><p className='signInBtnValue'>Login</p></button>
                </Card>
            </div>
        );
    }
});

export default SignIn;